<template>
  <v-container>

   
    <v-dialog
      v-model="confirmTransferdialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Are you sure?
        </v-card-title>

        <v-card-text>
         <h3 class="mt-5">You are transferring all {{formData.caseNumbers.length}} cause number{{formData.caseNumbers.length > 1 ? "s" : ''}} to Case Information <b>{{ selectedTransferTo }}</b>,<span style="color: red;">this can not be undone.</span></h3>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
      
            text
            @click="confirmTransferdialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="transferHandler"
          >
            I understand
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="transferDialog"
      
      width="500"
    >
      

      <v-card v-if="editMode">
        <v-form ref="transferForm">
        <v-card-title class="text-h5 grey lighten-2">
          Transfer Case Information
        </v-card-title>

        <v-card-text>
          <div class="mt-5">
          
          <v-select :rules="requiredRule" v-model="selectedTransferTo" label='Select Case Information to be transfered to' item-value="CaseID" item-text="CaseID" :items="cases"></v-select>

          <v-radio-group
      row
    >
      <v-radio
        label="Attorney"
        value="attorney"
      ></v-radio>
      <v-radio
        label="Social Worker"
        value="social worker"
      ></v-radio>
    </v-radio-group>

        </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="transferDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="confirmTransfer"
          >
            Transfer
          </v-btn>
        </v-card-actions>
      </v-form>
      </v-card>
    </v-dialog>

    <h2 v-if="editMode">
          Edit Case Information ID: {{ editContractObj?.CaseID }} {{editContractObj?.original_id ? "(Original ID : " + editContractObj?.original_id + ")"  : ""}}
      </h2>
      <div v-if="formData.userData">
        <v-card class="mb-3">
          <v-card-text>
            <v-text-field 
        class="mt-5 mb-5"
            dense

            label='Case Owner Name'
            v-model='formData.userData.name'
            disabled
          >
          </v-text-field>
          <v-text-field
            dense
            label='Case Owner Email'
            v-model='formData.userData.email'
            disabled
          >
          </v-text-field>
          
          <v-text-field
            dense
            label='Case Owner Role'
            v-model='formData.userData.role'
            disabled
          >
          </v-text-field>


          </v-card-text>
     
        </v-card>
        </div>
    <v-form ref='form'>
     
      <v-row>
        <v-card-title>
          Client
        </v-card-title>
        <v-col cols='12'>
          <v-text-field
            dense
            label='First Name'
            v-model='formData.clientFirstName'
          >
          </v-text-field>
        </v-col>
        <v-col cols='12'>
          <v-text-field
            dense
            label='Middle Name'
            v-model='formData.clientMiddleName'
          >
          </v-text-field>
        </v-col>
        <v-col cols='12'>
          <v-text-field
            dense
            label='Last Name'
            v-model='formData.clientLastName'
          >
          </v-text-field>
        </v-col>

        <v-col cols='12'>
      
          <raceControl :raceDataprops='formData.clientRace' @update:raceData="updateRaceData" />
        </v-col>
        <v-col cols='12'>
          <v-select
            :items="['AF (Alleged Father)','FR	(Father)','LC	(Legal Custodian)','MR (Mother)']"
            dense
            label='Relation'
            v-model='formData.clientRelation'
          >
          </v-select>
        </v-col>
        <v-card-title>
          Assignment
        </v-card-title>
       
        <v-col cols='12'>
          <v-select
            dense
            :items='countyList'
            label='County'
            v-model='formData.AssignmentCounty'
          >
          </v-select>
        </v-col>
        <v-col cols='12'>

          <v-autocomplete
            dense
            label='Assigned to'
            v-model='formData.ContractorId'
            :rules="requiredRule"
            :items="contractors"
            item-text="ContractorName"
            item-value="ContractorID"
          >
            <template v-slot:item="{ item }">
              <v-list-item-avatar
                color="indigo"
                class="text-h5 font-weight-light white--text"
              >
                {{ item.ContractorName.charAt(0) }}
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.ContractorName"></v-list-item-title>
                <v-list-item-subtitle v-text="item.ContractorTitle"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>

        </v-col>
        <v-col cols='12'>

          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :rules="requiredRule"
                dense
                label='Open Date'
                v-model='formData.AssignDate'
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formData.AssignDate"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>

        </v-col>
        <v-col cols='12'>
          <v-text-field
            dense
            type="number"
            label='Star Code'
            v-model='formData.StarCode'
          >
          </v-text-field>
        </v-col>
        <v-card-title>
          Status
        </v-card-title>
        <v-col cols='12'>
          <v-select
            :items='statusData'
            label='Status'
            v-model='formData.Status'
            
          >
            Status
          </v-select>
        </v-col>
        <v-card-title>
          Information
        </v-card-title>
        
        <!-- <v-col cols='12'>
          
          <v-card class="mb-5" :key="i"
      v-for="(c, i) in formData.caseNumbers">
          <v-card-text>
            <v-text-field
      label="Cause Numbers"
      v-model="formData.caseNumbers[i].caseNumber"
      :rules="formData.caseNumbers[i].AssignmentType === 'PRE (Prefilling)' ? requiredRule : []"
      @input="formatCaseNumber(i)"
      maxlength="13" 
    >
      <template v-slot:prepend>
        <v-select
           @change="() => typechangehandler(i)"
          class="mr-5"
          :items="['DEP (Dependency)', 'TG (Termination or Guardianship)', 'PRE (Prefilling)', 'SCEEP']"
          dense
          label='Type'
          v-model='formData.caseNumbers[i].AssignmentType'
        ></v-select>
        <v-select
          @change="(item) => updateSelect(item,i)"
          placeholder="Status"
          v-model="formData.caseNumbers[i].status"
          dense
          :items="statusvalues(formData.caseNumbers[i].AssignmentType)"
        ></v-select>
      </template>
      <template v-slot:append-outer>
        <v-btn small @click="removeCase(i)">
          Delete
        </v-btn>
      </template>
    </v-text-field>

    <v-row>
   
              <v-col  lg='4' cols="12">
                <v-text-field v-model="formData.caseNumbers[i].closedDate" label="Closed Date" type="date"></v-text-field>
              </v-col>
              <v-col  lg='4' cols="12">
                <v-select v-model="formData.caseNumbers[i].incarcerationInfo" label="Incarceration Information" :items="yesNoOptions"></v-select>
              </v-col>
              <v-col lg='4' cols="12">
                <v-select v-model="formData.caseNumbers[i].expertUsed" label="Expert Used" :items="yesNoOptions"></v-select>
              </v-col>
              <v-col   cols="12" lg='6'>
                <v-select dense v-model="formData.caseNumbers[i].expertTypes" chips label="Expert Types" multiple :items="expertTypeOptions"></v-select>
              </v-col>
              <v-col cols="12" lg='6'>
                <v-select dense v-model="formData.caseNumbers[i].outcomes" chips label="Outcomes" multiple :items="OutcomesOptions"></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          </v-card>

         

      </v-col> -->

      <v-col cols="12">
    <v-card class="mb-5" v-for="(caseData, i) in formData.cases" :key="i">
      <v-card-text>
        <!-- Dates and Case Number Row -->
        <v-row>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="caseData.arrestDate"
              label="Arrest/incident date"
              type="date"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="caseData.appointmentDate"
              label="Appointment date"
              type="date"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="4">
            <v-text-field
              label="Case number"
              v-model="caseData.caseNumber"
              :rules="[v => !!v || 'Case number is required']"
              @input="formatCLJNumber(i)"
              maxlength="13"
              dense
            >
              <template v-slot:append-outer>
                <v-btn small @click="removeCase(i)">
                  Delete
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <!-- Jurisdiction and Charges Row -->
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field
              label="Original charge and amended charges"
              v-model="caseData.charges"
              dense
            >
              <template v-slot:prepend>
                <v-select
                  class="mr-5"
                  :items="courtOptions"
                  dense
                  label="Jurisdiction"
                  v-model="caseData.jurisdiction"
                ></v-select>
              </template>
              <template v-slot:append>
                <v-btn small>
                  Search
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        
        <!-- Demographics Row -->
        <v-row>
          <v-col cols="12" lg="6">
            <v-select
              v-model="caseData.demographics.language"
              :items="languageOptions"
              label="Language"
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" lg="3">
            <v-select
              v-model="caseData.demographics.age"
              :items="ageRange"
              label="Age"
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" lg="3">
            <v-select
              v-model="caseData.demographics.gender"
              :items="genderOptions"
              label="Gender"
              dense
            ></v-select>
          </v-col>
        
            <raceControl :raceDataprops='caseData.clientRace' @update:raceData="updateCaseRaceData" />

         
         
        </v-row>

        <!-- Diversion Status -->
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="caseData.diversionStatus"
              :items="diversionOptions"
              label="Diversion status"
              dense
            ></v-select>
          </v-col>
        </v-row>

        <!-- Expert Costs Row -->
        <v-row>
          <v-col cols="12" lg="4">
            <v-select
              v-model="caseData.expertCosts.investigative"
              :items="yesNoOptions"
              label="Investigative cost"
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" lg="4">
            <v-select
              v-model="caseData.expertCosts.litigation"
              :items="yesNoOptions"
              label="Litigation cost"
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" lg="4">
            <v-select
              v-model="caseData.expertCosts.online"
              :items="yesNoOptions"
              label="Online form reporting"
              dense
            ></v-select>
          </v-col>
        </v-row>

        <!-- Time Tracking -->
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="caseData.timeTracking.category"
              :items="timeTrackingOptions"
              label="Time tracking by task/category"
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" v-if="caseData.timeTracking.category === 'Other'">
            <v-textarea
              v-model="caseData.timeTracking.description"
              label="Describe other task/category"
              counter="500"
              :rules="[v => !v || v.length <= 500 || 'Max 500 characters']"
              dense
              rows="3"
            ></v-textarea>
          </v-col>
        </v-row>

        <!-- Disposition Row -->
        <v-row>
          <v-col cols="12" lg="6">
            <v-select
              v-model="caseData.disposition"
              :items="dispositionOptions"
              label="Disposition"
              dense
            >
              <template v-slot:append-outer v-if="caseData.disposition === 'Other'">
                <v-text-field
                  v-model="caseData.dispositionOther"
                  label="Specify other"
                  maxlength="50"
                  dense
                ></v-text-field>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="caseData.dispositionDate"
              label="Disposition date"
              type="date"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Sentence Row -->
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field
              v-model="caseData.sentence.text"
              label="Sentence"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3">
            <v-select
              v-model="caseData.sentence.jailTime"
              :items="yesNoOptions"
              label="Jail time"
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" lg="3">
            <v-text-field
              v-model="caseData.sentence.fine"
              label="Fine amount"
              prefix="$"
              type="number"
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- RALJ Appeal -->
        <v-row>
          <v-col cols="6">
          <v-text-field
              v-model="caseData.dateOfSentencing"
              label="Date of sentencing"
              type="date"
              dense
            ></v-text-field>
            </v-col>  
          <v-col cols="6">
            <v-radio-group
              v-model="caseData.raljAppeal"
              label="RALJ appeal filed?"
              row
              dense
            >
              <v-radio label="Yes" value="Y"></v-radio>
              <v-radio label="No" value="N"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
    
  
  </v-col>

      

      <v-col cols="12">
          <v-btn  @click='addCaseNumber'>
            + Add Cause Number
          </v-btn>
        </v-col>
          <v-btn
        @click='saveBtnHandler'
        class='mt-3 mb-3'
        block
        color='primary'
      >
        {{editMode ? "Edit" : "Save"}}
      </v-btn>
      
      <v-btn color="warning" block v-if="role === 'Program_Admin' || role === 'Super_Admin' " :disabled="!editMode || formData.caseNumbers.length === 0" @click='transferDialog = true' class="mb-3">
            Transfer Case Information
          </v-btn>

      </v-row>
     
     

     

    </v-form>


    <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <v-card>
        <v-card-title>Are you sure you want to remove this cause number?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
           
            text
            @click="dialog = false"
          >
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmRemoveCase()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-container>
</template>

<script>
import raceControl from "../components/raceControl"
import { mapFields } from "vuex-map-fields";
import { getContractors } from "../dataservices/contractorsDataservices";
import { createCase, getCases, removeCause, transferAction, updateCase } from "../dataservices/caseDataService";
export default {
  
  components:{
    raceControl
  },
  props: {
    editMode: Boolean,
    editContractObj: Object,
  },
  async mounted() {
    if(this.editMode){
    this.formData = this.editContractObj;
    this.cases = await getCases();
    }
    this.contractors = await getContractors();

  },
  methods: {
  
    statusvalues(item){
     if(item === "SCEEP"){
      return ["Dismissed","Continued"];
     }else{
     return  ["Open","Closed","ADM TSF"];
     }
    },
    updateRaceData(data) {
      this.formData.clientRace = data;
    },
    updateCaseRaceData(data) {
      this.formData.caseData.clientRace = data;
    },
    updateSelect(){
      this.$forceUpdate();
    },
    addCaseNumber(){
      this.formData.caseNumbers.push({
            outcomes : [],
            expertTypes : [],
            caseNumber : "",
            status : "",
            AssignmentType: null,
          })
          this.$forceUpdate();
    },
    saveBtnHandler(){
      this.editMode ? this.edit() : this.save()
    },
    async edit(){
      this.$forceUpdate();
      let valid = this.$refs.form.validate();
      if (valid) {
        await updateCase(this.$route.params.id,this.formData);
        location.reload();
      }
    
   
    },


    
    formatCaseNumber(index) {
      let value = this.formData.caseNumbers[index].caseNumber.replace(/[^0-9]/g, '');
      let formattedValue = '';
      for (let i = 0; i < value.length; i++) {
        if (i === 2 || i === 3 || i === 8) {
          formattedValue += '-';
        }
        formattedValue += value[i];
      }
      this.formData.caseNumbers[index].caseNumber = formattedValue;
      this.$forceUpdate();
    },

    async confirmRemoveCase(){
      if(this.editMode){
        if(this.formData.caseNumbers[this.selectedCaseNumber].CaseNumberID){
          await removeCause(this.formData.caseNumbers[this.selectedCaseNumber]);
        }
      }
      this.formData.caseNumbers.splice(this.selectedCaseNumber,1);
      this.dialog = false;
    },
    confirmTransfer(){
      let valid = this.$refs.transferForm.validate();
      if (valid) {
      this.confirmTransferdialog = true; 
      }
     
    },
    async transferHandler(){
      await transferAction(this.$route.params.id,this.formData.caseNumbers, this.selectedTransferTo);
      this.confirmTransferdialog = false;
      this.transferDialog = false; 
    },
    removeCase(i){
      this.dialog = true;
      this.selectedCaseNumber = i;
    },
    async save() {
      let valid = this.$refs.form.validate();
      if (valid) {
        await createCase(this.formData);
        this.$router.push("/cases");
      }
    },
  },

  computed: {
    ...mapFields(["role"]),
  },
  data() {
    return {
      timeTrackingOptions: [
      'Client meeting or communications',
      'Non-client communications',
      'Investigation',
      'Records review',
      'Case work',
      'Legal research and drafting',
      'Prep for court appearance',
      'Court appearance',
      'Other'
    ],

    courtOptions: [
      'Municipal Court',
      'District Court',
      'Superior Court',
      'Federal Court'
    ],

    ageRange: Array.from({length: 83}, (_, i) => i + 18),

    genderOptions: [
      'M',
      'F',
      'Non-binary',
      'Transgender',
      'Other',
      'Multiple'
    ],

    languageOptions: [
      'English',
      'Spanish',
      'Other'
    ],

    diversionOptions: [
      'Not offered',
      'Pending',
      'Completed',
      'Case dismissed',
      'Revoked'
    ],

    dispositionOptions: [
      'Acquitted',
      'Dismissed',
      'Pled guilty as charged',
      'Pled to lesser charge',
      'Guilty verdict',
      'Other'
    ],
      selectedTransferTo : null,
      cases : [],
      confirmTransferdialog : false,
      transferDialog : false,
      OutcomesOptions : [
      "D11 Dismissal – RCW 11.130 Guardianship Established",
  "D13 Dismissal – RCW 13.36 Guardianship Established",
  "DNP Dismissal – No Dependency Established",
  "RPR Relinquishment of Parental Rights",
  "RWC Reunification with Client",
  "RWOP Reunification with Other Parent",
  "TPR Termination of Parental Rights"
      ],
      yesNoOptions: ['Yes', 'No'],
      expertTypeOptions: ["Travel (Attorney and Expert)",
  "Training/CLE’s",
  "Polygraph",
  "SW Forensic Review",
  "Medical Forensic Review",
  "Home Study",
  "Medical Records",
  "Drug/Alcohol Related",
  "DV Evaluation",
  "Interpreter Services",
  "Other",
  "Paternity",
  "Psychological/Mental",
  "Trial Testimony",
  "Transcripts",
  "Psychosexual Evaluation",
  "Deposition",
  "Court Records",
  "Parenting Evaluation/Bonding Attachment"], // Add actual expert types here,
      mask: "##-#-#####-##",
      selectedCaseNumber : null,
      dialog: false,
      contractors: [],
      countyList: [
        "Adams",
        "Asotin",
        "Benton",
        "Chelan",
        "Clallam",
        "Clark",
        "Columbia",
        "Cowlitz",
        "Douglas",
        "Ferry",
        "Franklin",
        "Garfield",
        "Grant",
        "Grays Harbor",
        "Island",
        "Jefferson",
        "King",
        "Kitsap",
        "Kittitas",
        "Klickitat",
        "Lewis",
        "Lincoln",
        "Mason",
        "Okanogan",
        "Pacific",
        "Pend Oreille",
        "Pierce",
        "San Juan",
        "Skagit",
        "Skamania",
        "Snohomish",
        "Spokane",
        "Stevens",
        "Thurston",
        "Wahkiakum",
        "Walla Walla",
        "Whatcom",
        "Whitman",
        "Yakima",
      ],
      statusData: ["Open", "Closed"],
      requiredRuleArray : [(v) => v.length !== 0 ||  "This Field is required"],
      requiredRule: [(v) => !!v || "This Field is required"],
      confirmdialog: false,
      menu: false,
      formData: {
        clientFirstName: null,
        clientMiddleName: null,
        clientLastName: null,
        clientRace: null,
        clientRelation: null,
        AssignmentCounty: null,
        ContractorId: null,
        AssignDate: null,
        StarCode: null,
        Status: null,
        cases: [
        {
          arrestDate: '',
          appointmentDate: '',
          caseNumber: '',
          jurisdiction: '',
          dateOfSentencing : "",
          charges: '',
          demographics: {
            age: '',
            gender: [],
            race: '',
            ethnicity: '',
            language: ''
          },
          diversionStatus: '',
          expertCosts: {
            investigative: 'N',
            litigation: 'N',
            online: 'N'
          },
          timeTracking: {
            category: '',
            description: ''
          },
          disposition: '',
          dispositionOther: '',
          dispositionDate: '',
          sentence: {
            text: '',
            jailTime: 'N',
            fine: ''
          },
          raljAppeal: 'N'
        }
      ],
        caseNumbers: [{
          caseNumber : "",
          status : null,
          outcomes : [],
          expertTypes : [],
          AssignmentType : null,
        }],
      },
    };
  },
};
</script>

<style>
</style>